import styled from '@emotion/styled';

import { ReleaseSplashPage } from '@app/api/resources/Releases';

import useAppSelector from '@app/hooks/utils/useAppSelector';

import PageSection from '@app/components/layout/PageSection';
import SimpleNav from '@app/components/layout/SimpleNav';
import ReleaseCTA from '@app/components/release/ReleaseCTA';
import ResponsiveImage from '@app/components/responsive-images/ResponsiveImage';

const ReleaseHeader = ({
  releaseSplashPage,
}: {
  releaseSplashPage: ReleaseSplashPage;
}) => {
  const {
    background_video_url: backgroundVideoUrl,
    film_title: filmTitle,
    title_image: titleImageUrl,
    short_description: shortDescription,
    short_description_color: shortDescriptionColor,
    mobile_background_image: mobileImageUrl,
  } = releaseSplashPage;

  const isMobile = useAppSelector(state => state.appState.isMobile);

  return (
    <Container>
      <VideoContainer>
        {isMobile ? (
          <Video as="img" src={`${mobileImageUrl}?size=600x`} />
        ) : (
          <Video
            autoPlay
            loop
            muted
            playsInline
            preload="auto"
            src={backgroundVideoUrl}
          />
        )}
      </VideoContainer>
      <Overlay>
        <PageSection fullHeight>
          <SimpleNav />
          <HeadingSubheadingContainer>
            <Heading>
              <ImageContainer>
                <ResponsiveImage
                  altText={filmTitle}
                  imageWidth="full"
                  imageUrl={titleImageUrl}
                />
              </ImageContainer>
            </Heading>
            <div>
              <Subheading color={shortDescriptionColor}>
                {shortDescription}
              </Subheading>
              <ReleaseCTA releaseSplashPage={releaseSplashPage} />
            </div>
          </HeadingSubheadingContainer>
        </PageSection>
      </Overlay>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
`;

const Overlay = styled.div`
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: relative;
  overflow: hidden;
`;

const VideoContainer = styled.div`
  z-index: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
`;

const Video = styled.video`
  opacity: 0.7;
  position: fixed;
  min-width: 100%;
  top: 50%;
  right: 50%;
  transform: translateX(50%) translateY(-50%);
`;

const HeadingSubheadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 40px;
  padding-bottom: 75px;
  height: calc(100% - 60px);
`;

const Heading = styled.h1`
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    width: 50vh;
  }
  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    width: 550px;
  }
`;

const Subheading = styled.h2<{ color?: string }>`
  width: 100%;
  color: ${props =>
    props.color ? `#${props.color}` : props.theme.color.white};
  font-size: 26px;
  line-height: 29px;
  white-space: pre-line;
  padding-top: 30px;
`;

const ImageContainer = styled.div`
  min-width: 300px;
  max-width: 300px;
  max-height: 200px;
`;

export default ReleaseHeader;
