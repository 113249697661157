import StarRatings from 'react-star-ratings';

type StarRatingProps = {
  rating: number;
  starRatedColor: string;
  starEmptyColor: string;
  starDimension?: string;
  starSpacing?: string;
};

const StarRating = ({
  rating,
  starRatedColor,
  starEmptyColor,
  starDimension = '28px',
  starSpacing = '4px',
}: StarRatingProps) => {
  if (typeof rating !== 'number') {
    return null;
  }

  return (
    <StarRatings
      rating={rating}
      starRatedColor={starRatedColor}
      starEmptyColor={starEmptyColor}
      starDimension={starDimension}
      starSpacing={starSpacing}
      numberOfStars={5}
    />
  );
};

export default StarRating;
