import styled from '@emotion/styled';

const MUBI_APP_STORE_ID = '626148774';
const GO_APP_STORE_ID = '1406769786';

type Platform = 'android' | 'ios';

export const getAppStoreUrl = (platform: Platform, isGo?: boolean) => {
  if (platform === 'android') {
    if (isGo) {
      return 'https://play.google.com/store/apps/details?id=com.mubi.go';
    }
    return 'https://play.google.com/store/apps/details?id=com.mubi';
  }
  if (platform === 'ios') {
    if (isGo) {
      return `https://itunes.apple.com/app/apple-store/id${GO_APP_STORE_ID}`;
    }
    return `https://itunes.apple.com/app/apple-store/id${MUBI_APP_STORE_ID}?pt=2050088&ct=Modal&mt=8`;
  }
  return null;
};

const getPlatformName = (platform: Platform) => {
  if (platform === 'android') {
    return 'Google Play';
  }
  if (platform === 'ios') {
    return 'App Store';
  }
  return null;
};

type BadgeProps = {
  isAndroid: boolean;
};
const Badge = styled.a<BadgeProps>`
  display: inline-block;
  background-image: ${`url('${process.env.assetPrefix}/static/images/app-badges.png')`};
  background-size: 100%;
  background-position: ${props => (props.isAndroid ? 'bottom' : 'top')};
  width: 100%;
  height: 100%;

  /* hide text */
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
`;

type AppStoreBadgeProps = {
  platform: Platform;
  isGo?: boolean;
};

const AppStoreBadge = ({ platform, isGo = false }: AppStoreBadgeProps) => {
  const url = getAppStoreUrl(platform, isGo);
  const isAndroid = platform === 'android';
  return (
    <Badge
      target="_blank"
      rel="noopener noreferrer"
      href={url}
      isAndroid={isAndroid}
    >
      {getPlatformName(platform)}
    </Badge>
  );
};

export default AppStoreBadge;
