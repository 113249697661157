import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import useAppSelector from '@app/hooks/utils/useAppSelector';

import AppStoreBadge from '@app/components/AppStoreBadge';
import { MubiLogoIcon } from '@app/components/icons/LogoIcons';
import ImageBase from '@app/components/ImageBase';
import PageSection from '@app/components/layout/PageSection';

const ReleaseFooter = () => {
  const { t } = useTranslation('release');

  const pricePerMonth = useAppSelector(
    state => state.user.subscriptionPlans.month?.price,
  );

  return (
    <Container>
      <PageSection>
        <AboutMubiGrid>
          <AboutMubiItem>
            <AboutMubiLogo
              src={`${process.env.assetPrefix}/promos/calendar-black.svg`}
            />
            <AboutText as="h3">
              {t('release:release.footer.watch_great_cinema.heading')}
            </AboutText>
            <AboutText>
              {t('release:release.footer.watch_great_cinema.body')}
            </AboutText>
          </AboutMubiItem>
          <AboutMubiItem>
            <AboutMubiLogo
              src={`${process.env.assetPrefix}/promos/desktop-black.svg`}
            />
            <AboutText as="h3">
              {t('release:release.footer.anytime_anywhere.heading')}
            </AboutText>
            <AboutText>
              {t('release:release.footer.anytime_anywhere.body')}
              {!!pricePerMonth
                ? ` ${t('release:release.footer.anytime_anywhere.with_price', {
                    price: pricePerMonth,
                  })}`
                : null}
            </AboutText>
          </AboutMubiItem>
          <AboutMubiItem>
            <AboutMubiLogo
              src={`${process.env.assetPrefix}/promos/tablet-black.svg`}
            />
            <AboutText as="h3">
              {t('release:release.footer.take_movies.heading')}
            </AboutText>
            <AboutText>
              {t('release:release.footer.take_movies.body')}
            </AboutText>
          </AboutMubiItem>
        </AboutMubiGrid>
        <MubiLogoContainer>
          <MubiLogoIcon width="150" />
        </MubiLogoContainer>
        <AppStoreBadges>
          <AppStoreBadge platform="ios" />
          <AppStoreBadge platform="android" />
        </AppStoreBadges>
      </PageSection>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${props => props.theme.color.white};
  position: relative;
`;

const AboutMubiGrid = styled.div`
  padding: 38px 0 0;
  display: flex;
  flex-direction: column;
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    flex-direction: row;
  }
`;

const AboutMubiItem = styled.div`
  padding-bottom: 35px;
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    width: 33%;
    &:not(:first-of-type) {
      margin-left: 30px;
    }
  }
`;

const AboutMubiLogo = styled(ImageBase)`
  height: 40px;
  margin-bottom: 15px;
`;

const AboutText = styled.p`
  line-height: 24px;
`;

const MubiLogoContainer = styled.div`
  padding: 38px 0;
`;

const AppStoreBadges = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fit, 169px);
  grid-auto-rows: 50px;
  padding: 20px 0 40px;
`;

export default ReleaseFooter;
