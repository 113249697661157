import HttpService from '@app/api/http-service';
import { FilmId } from '@app/api/resources/Film';
import { ObjectOfStrings } from '@app/api/utility-types';

export type ReleaseMessage = {
  text: string;
  type: 'default' | 'emphasized';
}[];

export type Release = {
  film_id: FilmId;
  mubi_release: boolean;
  day_and_date: boolean;
  theatrical_release_date: string;
  theatrical_end_date: string;
  publish_date: string;
  stops_promoting_on: string;
  streaming_releases_on: string;
  poster_url: string;
  film: {
    id: FilmId;
    slug: string;
    title: string;
  };
  film_date_message: string[];
  film_release_date_messages: ReleaseMessage[];
  has_programming: boolean;
};

export type ReleaseSplashPage = {
  id: number;
  vanity_path: string;
  youtube_trailer_url: string;
  cinemas_iframe_url: string;
  enable_cinema_screenings_map: boolean;
  cinema_screenings: string;
  movie_glu_film_id: number;
  show_splash_page_when_streaming: boolean;
  reviews: {
    review_text: string;
    review_text_html: string;
    publisher: string;
    star_rating: number;
  }[];
  background_color: string;
  font_color: string;
  link_color: string;
  button_font_color: string;
  dark_background: boolean;
  button_background_color: string;
  button_border_color: string;
  button_hover_background_color: string;
  bg2_background_color: string;
  bg2_font_color: string;
  bg2_link_color: string;
  bg2_button_font_color: string;
  bg2_button_background_color: string;
  bg2_button_border_color: string;
  bg2_button_hover_background_color: string;
  bg2_dark_background: boolean;
  short_description_color: string;
  about_the_film: string;
  about_the_film_html: string;
  watch_on_mubi: string;
  watch_on_mubi_html: string;
  special_screening: string;
  special_screening_html: string;
  special_screening_coming_soon: string;
  special_screening_coming_soon_html: string;
  email_campaign: string;
  special_promo_id: string;
  film_title: string;
  film_url: string;
  short_description: string;
  background_video_url: string;
  mobile_background_image: string;
  title_image: string;
  valid_for_country: boolean;
  is_expired: boolean;
  is_episode: boolean;
  is_released: boolean;
  geo_location: string;
  release: Release;
};

export const releasesForAboutPage = (
  httpContext: ObjectOfStrings,
): Promise<{ data: Release[] }> =>
  HttpService(httpContext).get('/releases/for_about_page');

export const getReleasesForFilmDetailsPage = (
  httpContext: ObjectOfStrings,
  filmId: number,
): Promise<{ data: Release[] }> =>
  HttpService(httpContext).get(`/releases?film_ids=${filmId}`);

export const getReleasesSplashPageForVanityPath = (
  httpContext: ObjectOfStrings,
  vanityPath: string,
): Promise<{ data: ReleaseSplashPage }> =>
  HttpService(httpContext).get(`/releases/splash_pages/${vanityPath}`);

export const countryLngLats = {
  IE: {
    lat: 53.3498,
    lng: -6.2603,
  },
  US: {
    lat: 40.721191,
    lng: -73.843353,
  },
  GB: {
    lat: 51.515419,
    lng: -0.141099,
  },
  DE: {
    lat: 52.5170365,
    lng: 13.3888599,
  },
  CA: {
    lat: 43.6532,
    lng: -79.3832,
  },
} as const;
