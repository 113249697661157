import { scroller } from 'react-scroll';
import useTranslation from 'next-translate/useTranslation';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { ReleaseSplashPage } from '@app/api/resources/Releases';

import useAppSelector from '@app/hooks/utils/useAppSelector';

import MubiButton from '@app/components/buttons/MubiButton/MubiButton';
import MubiButtonLink from '@app/components/buttons/MubiButton/MubiButtonLink';

const ReleaseCTA = ({
  releaseSplashPage,
}: {
  releaseSplashPage: ReleaseSplashPage;
}) => {
  const { t } = useTranslation('release');

  const geoLocation = useAppSelector(state => state.user.geoLocation);
  const cta = getCTATextAndUrl(releaseSplashPage, geoLocation);

  if (!cta) {
    return null;
  }
  return (
    <>
      {!!cta.onClick ? (
        <StyledButton
          backgroundColor={releaseSplashPage.button_background_color}
          borderColor={releaseSplashPage.button_border_color}
          color={releaseSplashPage.button_font_color}
          hoverBackgroundColor={releaseSplashPage.button_hover_background_color}
          clickAction={cta.onClick}
        >
          {t(`release:release.cta.${cta.phrase_key}`)}
        </StyledButton>
      ) : (
        <StyledButtonLink
          backgroundColor={releaseSplashPage.button_background_color}
          borderColor={releaseSplashPage.button_border_color}
          color={releaseSplashPage.button_font_color}
          hoverBackgroundColor={releaseSplashPage.button_hover_background_color}
          url={cta.href}
        >
          {t(`release:release.cta.${cta.phrase_key}`)}
        </StyledButtonLink>
      )}
    </>
  );
};

export const getCTATextAndUrl = (
  releaseSplashPage: ReleaseSplashPage,
  geoLocation: string,
): { phrase_key: string; href?: string; onClick?: () => void } => {
  const hasScreenings =
    !!releaseSplashPage.cinemas_iframe_url ||
    !!releaseSplashPage.cinema_screenings ||
    releaseSplashPage.enable_cinema_screenings_map;

  const showWatchInCinemas = !releaseSplashPage.is_expired && hasScreenings;
  const showWatchOnMubi = releaseSplashPage.release.has_programming;
  const showInviteMe =
    !releaseSplashPage.is_released &&
    !showWatchOnMubi &&
    !!releaseSplashPage.email_campaign;

  const isUS = geoLocation === 'US';
  const bookOrGetTicketsKey = isUS ? 'get_tickets' : 'book_tickets';
  const watchInCinemasOrTheatersKey = isUS
    ? 'watch_in_theaters'
    : 'watch_in_cinemas';

  if (!releaseSplashPage.is_expired) {
    if (showWatchInCinemas) {
      return {
        phrase_key: !!releaseSplashPage.release.day_and_date
          ? watchInCinemasOrTheatersKey
          : bookOrGetTicketsKey,
        onClick: () => scrollToSection('book_tickets'),
      };
    } else if (showInviteMe) {
      return {
        phrase_key: 'watch_on_mubi',
        onClick: () => {}, // TODO scroll to section
      };
    }
  }
  if (showWatchOnMubi) {
    return {
      phrase_key: hasScreenings ? 'watch_on_mubi' : 'watch_now',
      href: releaseSplashPage.film_url,
    };
  }
  return null;
};

const scrollToSection = (sectionName: string) =>
  scroller.scrollTo(sectionName, {
    duration: 300,
    smooth: true,
    offset: 0,
  });

export const buttonStyle = props => css`
  color: #${props.color};
  background-color: #${props.backgroundColor};
  border: 1px solid #${props.borderColor};
  &:hover {
    color: #${props.color}!important;
    background-color: #${props.hoverBackgroundColor}!important;
    border: 1px solid #${props.hoverBackgroundColor}!important;
  }
  line-height: 1.1;
  margin-top: 30px;
  padding: 14px 16px;
  @media (min-width: ${props.theme.mqNew.tablet}) {
    font-size: 21px;
    padding: 22px 50px;
  }
`;

export type ButtonProps = {
  backgroundColor: string;
  borderColor: string;
  color: string;
  hoverBackgroundColor: string;
};

const StyledButtonLink = styled(MubiButtonLink)<ButtonProps>`
  ${buttonStyle}
`;

const StyledButton = styled(MubiButton)<ButtonProps>`
  ${buttonStyle}
`;

export default ReleaseCTA;
