import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';

import { getImageUrl, getResponsiveImageUrls } from '@app/services/images';

type ResponsiveImageProps = {
  imageUrl: string;
  altText: string;
  coverOrContain?: 'cover' | 'contain';
  imageHeight?: string;
  nativeLazyLoad?: 'eager' | 'lazy';
  displayAsBlock?: boolean;
  backgroundPosition?: string;
  imagePixelWidth?: number;
  decoding?: 'sync' | 'async' | 'auto';
  // What width of the screen does the image take up?
  imageWidth?: 'full' | 'two-thirds' | 'half' | 'third' | 'quarter' | 'fifth';
  onImageLoad?: () => void;
};

const ResponsiveImage = ({
  imageUrl,
  altText,
  nativeLazyLoad,
  imageWidth = 'full',
  coverOrContain = 'cover',
  imageHeight = 'auto',
  displayAsBlock = false,
  backgroundPosition = null,
  decoding = 'auto',
  imagePixelWidth = 800,
  onImageLoad,
}: ResponsiveImageProps) => {
  const [imageUrls, setImageUrls] = useState(
    getResponsiveImageUrls({ imageUrl, imageWidth }),
  );
  const [isLoaded, setIsLoaded] = useState(false);
  const imageRef = useRef(null);

  useEffect(() => setIsLoaded(imageRef.current?.complete), []);

  useEffect(() => {
    if (isLoaded && onImageLoad) {
      onImageLoad();
    }
  }, [isLoaded, onImageLoad]);

  useEffect(
    () => setImageUrls(getResponsiveImageUrls({ imageUrl, imageWidth })),
    [imageUrl, imageWidth],
  );

  if (!imageUrl) {
    return null;
  }

  const sources = imageUrls.map(anImageUrl => (
    <source
      media={anImageUrl.media}
      srcSet={anImageUrl.srcset}
      key={anImageUrl.key}
    />
  ));

  return (
    <>
      <StyledPicture
        imageHeight={isLoaded ? imageHeight : '0px'}
        displayAsBlock={displayAsBlock}
        data-testid="resp-img-src"
      >
        {sources}
        <StyledImage
          ref={imageRef}
          src={getImageUrl(imageUrl, imagePixelWidth)}
          alt={altText}
          coverOrContain={coverOrContain}
          imageHeight={imageHeight}
          isVisible={isLoaded}
          onLoad={() => setIsLoaded(true)}
          loading={nativeLazyLoad}
          displayAsBlock={displayAsBlock}
          backgroundPosition={backgroundPosition}
          decoding={decoding}
        />
      </StyledPicture>
    </>
  );
};

export default ResponsiveImage;

const StyledPicture = styled.picture<{
  imageHeight: string;
  displayAsBlock: boolean;
}>`
  display: block;
  height: ${props => props.imageHeight};
  ${props => props.displayAsBlock && 'display: block;'}
`;

// eslint-disable-next-line local-rules/no-styled-img-elements
const StyledImage = styled.img<{
  displayAsBlock: boolean;
  isVisible: boolean;
  imageHeight: string;
  coverOrContain: string;
  backgroundPosition: string;
}>`
  visibility: ${props => (props.isVisible ? 'visible' : 'hidden')};
  width: 100%;
  height: ${props => props.imageHeight};
  object-fit: ${props => props.coverOrContain};
  object-position: ${props => props.backgroundPosition};
  font-family: 'object-fit: ${props => props.coverOrContain};';
  ${props => props.displayAsBlock && 'display: block;'}

  /* Solves the space under the image problem */
  vertical-align: top;
`;
