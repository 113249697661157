import styled from '@emotion/styled';

import { color as themeColors, ColorKey } from '@app/themes/mubi-theme';

import useSnowplowClickTracker from '@app/hooks/snowplow/useSnowplowClickTracker';

import { MubiLogoIcon } from '@app/components/icons/LogoIcons';
import Link from '@app/components/Link';

const SimpleNav = ({ themeColor = 'white' }: { themeColor?: ColorKey }) => {
  const trackSnowplowClickEvent = useSnowplowClickTracker();
  return (
    <LogoLink
      href="/showing"
      onClick={() =>
        trackSnowplowClickEvent({ data: { clickType: 'tab_mubi_logo' } })
      }
    >
      <MubiLogoIcon
        width="90px"
        logoColor={themeColors[themeColor]}
        dotsColor={themeColors[themeColor]}
      />
    </LogoLink>
  );
};

const LogoLink = styled(Link)`
  margin-top: 30px;
  display: inline-block;
`;

export default SimpleNav;
