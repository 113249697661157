import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import { ReleaseSplashPage } from '@app/api/resources/Releases';

import PageSection from '@app/components/layout/PageSection';
import StarRating from '@app/components/StarRating';

const AboutTheFilm = ({
  releaseSplashPage,
}: {
  releaseSplashPage: ReleaseSplashPage;
}) => {
  const { t } = useTranslation('release');
  const {
    font_color: fontColor,
    background_color: bgColor,
    youtube_trailer_url: youtubeTrailerUrl,
    reviews,
  } = releaseSplashPage;

  return (
    <Container fontColor={fontColor} bgColor={bgColor}>
      <PageSection>
        <InnerContainer>
          <AboutSection>
            <AboutTitle>{t('release:release.about_heading')}</AboutTitle>
            <AboutText
              dangerouslySetInnerHTML={{
                __html: releaseSplashPage.about_the_film_html,
              }}
            />
            <Iframe
              width="100%"
              height="286"
              src={youtubeTrailerUrl}
              className="chromatic-ignore"
            />
          </AboutSection>
          <ReviewSection>
            {reviews.map(review => (
              <Review key={review.review_text}>
                {review.star_rating ? (
                  <StarRating
                    rating={review.star_rating}
                    starRatedColor={`#${fontColor}`}
                    starEmptyColor={`#${bgColor}`}
                    starDimension="24px"
                    starSpacing="3px"
                  />
                ) : null}
                <ReviewQuote>{review.review_text}</ReviewQuote>
                <ReviewPublication>{review.publisher}</ReviewPublication>
              </Review>
            ))}
          </ReviewSection>
        </InnerContainer>
      </PageSection>
    </Container>
  );
};

const Container = styled.div<{ fontColor: string; bgColor: string }>`
  color: #${props => props.fontColor};
  background-color: #${props => props.bgColor};
  padding-top: 38px;
  position: relative;
  z-index: 1;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    flex-direction: row;
    line-height: 24px;
  }
`;

const Column = styled.div`
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    width: 45%;
  }
`;

const AboutSection = styled(Column)`
  margin-bottom: 50px;
`;

const ReviewSection = styled(Column)`
  text-transform: uppercase;
`;

const AboutTitle = styled.h2`
  font-size: 22px;
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    font-size: 30px;
  }
  margin-bottom: 25px;
`;

const AboutText = styled.div`
  & p:not(:last-of-type) {
    margin-bottom: 20px;
  }
`;

const Review = styled.div`
  margin-bottom: 30px;
`;

const ReviewQuote = styled.p``;

const ReviewPublication = styled.p`
  font-size: 13px;
`;

const Iframe = styled.iframe`
  margin-top: 38px;
`;

export default AboutTheFilm;
