import styled from '@emotion/styled';

const CinemaScreeningsText = ({
  screeningsJSON,
  linkColor,
}: {
  screeningsJSON: string;
  linkColor: string;
}) => {
  const screenings: {
    header: string;
    cinemas: { cinema: string; city: string; cta: string; url: string }[];
  }[] = JSON.parse(screeningsJSON);

  return (
    <>
      {screenings.map(screeningSection => (
        <TicketsSection key={screeningSection.header}>
          <h3>{screeningSection.header}</h3>
          <ul>
            {screeningSection.cinemas.map(cinemaItem => (
              <li key={cinemaItem.cinema}>
                {cinemaItem.cinema}
                {!!cinemaItem.city ? `, ${cinemaItem.city}` : null}
                {cinemaItem.cta ? (
                  <>
                    {' - '}
                    <BookTicketsLink
                      linkColor={linkColor}
                      href={cinemaItem.url}
                    >
                      {cinemaItem.cta}
                    </BookTicketsLink>
                  </>
                ) : null}
              </li>
            ))}
          </ul>
        </TicketsSection>
      ))}
    </>
  );
};

const TicketsSection = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 24px;
  }
`;

const BookTicketsLink = styled.a<{ linkColor: string }>`
  color: #${props => props.linkColor};
  &:hover,
  :visited {
    color: #${props => props.linkColor};
  }
`;

export default CinemaScreeningsText;
