import dynamic from 'next/dynamic';
import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import { countryLngLats, ReleaseSplashPage } from '@app/api/resources/Releases';

import useAppSelector from '@app/hooks/utils/useAppSelector';

import PageSection from '@app/components/layout/PageSection';
import CinemaScreeningsText from '@app/components/release/CinemaScreeningsText';

const SectionScreeningTimes = dynamic(
  () =>
    import(
      /* webpackChunkName: "SectionScreeningTimes" */
      '@app/screening-times/src/SectionScreeningTimes'
    ),
);

const WatchInCinemas = ({
  releaseSplashPage,
}: {
  releaseSplashPage: ReleaseSplashPage;
}) => {
  const { t } = useTranslation('release');

  const geoLocation = useAppSelector(state => state.user.geoLocation);

  let cinemaScreeningsElement: JSX.Element = null;

  const showCinemaScreeningsWidget =
    releaseSplashPage.enable_cinema_screenings_map &&
    !!releaseSplashPage.movie_glu_film_id &&
    ['GB', 'IE', 'US', 'DE', 'CA'].includes(geoLocation);

  if (showCinemaScreeningsWidget) {
    const initialLat = countryLngLats?.[geoLocation].lat;
    const initialLng = countryLngLats?.[geoLocation].lng;

    cinemaScreeningsElement = (
      <SectionScreeningTimes
        filmId={releaseSplashPage.movie_glu_film_id}
        initialLat={initialLat}
        initialLong={initialLng}
        userCountryCode={geoLocation}
      />
    );
  } else if (!!releaseSplashPage.cinemas_iframe_url) {
    cinemaScreeningsElement = (
      <iframe
        title="Book tickets embed"
        src={releaseSplashPage.cinemas_iframe_url}
        height="600px"
        width="100%"
      />
    );
  } else if (!!releaseSplashPage.cinema_screenings) {
    cinemaScreeningsElement = (
      <CinemaScreeningsText
        screeningsJSON={releaseSplashPage.cinema_screenings}
        linkColor={releaseSplashPage.link_color}
      />
    );
  } else {
    return null;
  }

  return (
    <Container id="book_tickets">
      <PageSection>
        <Heading>
          {t(
            `release:release.cta.${
              geoLocation === 'US' ? 'get_tickets' : 'book_tickets'
            }`,
          )}
        </Heading>

        {releaseSplashPage.special_screening_html && (
          <SpecialScreeningTitle
            dangerouslySetInnerHTML={{
              __html: releaseSplashPage.special_screening_html,
            }}
          />
        )}

        <CinemaScreeningContainer>
          {cinemaScreeningsElement}
        </CinemaScreeningContainer>

        {releaseSplashPage.special_screening_coming_soon_html && (
          <SpecialScreeningComingSoonTitle
            dangerouslySetInnerHTML={{
              __html: releaseSplashPage.special_screening_coming_soon_html,
            }}
          />
        )}
      </PageSection>
    </Container>
  );
};

const Container = styled.div`
  background-color: white;
  position: relative;
  padding: 38px 0;
`;

const Heading = styled.h2`
  font-size: 30px;
`;

const CinemaScreeningContainer = styled.div`
  margin-top: 25px;
`;

const SpecialScreeningTitle = styled.div`
  font-size: 18px;
  margin-top: 9px;
`;

const SpecialScreeningComingSoonTitle = styled(SpecialScreeningTitle)`
  margin-top: 25px;
  color: ${props => props.theme.color.midGray};
`;

export default WatchInCinemas;
